import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import styled from "styled-components"

import Header from "../../components/header"
import GlobalStyle from "../../components/GlobalStyle"
import Theme from "../../components/Theme"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Constraints from "../../components/Constraints"
import AnimateUp from "../../components/AnimateUp"

export default props => (
  <Theme>
    <GlobalStyle />
    <SEO title="Shotty Help Center" description="Get help using Shotty." />
    <Header />
    <Layout>
      <Constraints maxWidth="1040px">
        <Styles>
          <AnimateUp delay="0.2s">
            <Marquee>
              <h3>Help Center</h3>
            </Marquee>
          </AnimateUp>
          <AnimateUp delay="0.4s">
            <a
              href="https://support.apple.com/en-us/HT201361"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BigCell
                caption="GET STARTED"
                title="How To Take Screenshots"
                description="Shotty lets you use the default MacOS screenshot tools to capture screenshots. Here’s how."
                image={props.data.thumb1.childImageSharp.fluid}
              />
            </a>
          </AnimateUp>
          <AnimateUp delay="0.6s">
            <Link to="/help/change-screenshot-folder">
              <BigCell
                caption="PRO TIP"
                title="Customize Where Your Screenshots Are Saved"
                description="Keep a clean Desktop. Follow these simple steps to save your screenshots to a custom folder."
                image={props.data.thumb2.childImageSharp.fluid}
              />
            </Link>
          </AnimateUp>
          <AnimateUp delay="0.7s">
            <ContactCell>
              <h5>Still need need help?</h5>
              <p>
                Get in touch and I’ll do my best to help you. I love hearing
                from Shotty users.
              </p>
              <p>- Jacob Ruiz</p>
              <ButtonSection>
                <a
                  href="https://twitter.com/JacobRuizDesign"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RoundButton>Twitter</RoundButton>
                </a>
                <a href="mailto:jacobruizdesign@gmail.com">
                  <RoundButton>Email</RoundButton>
                </a>
              </ButtonSection>
            </ContactCell>
          </AnimateUp>
        </Styles>
      </Constraints>
    </Layout>
  </Theme>
)

export const pageQuery = graphql`
  query {
    thumb1: file(relativePath: { eq: "help-thumb-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    thumb2: file(relativePath: { eq: "help-thumb-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 560) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

const BigCell = ({ caption, title, description, image }) => (
  <BigCellLayout>
    <div className="col1">
      <h6>{caption}</h6>
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
    <div className="col2">
      <Img fluid={image} className="img" />
    </div>
  </BigCellLayout>
)

const BigCellLayout = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-gap: 30px;
  padding: 30px 0;
  border-bottom: ${props => props.theme.line};
  cursor: pointer;
  .col1 {
    max-width: 500px;
    align-self: center;
  }
  .col2 {
    align-self: center;
    .img {
      max-width: 385px;
      border-radius: 6px;
    }
  }
  transition: 2s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
    transform: scale(0.99);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    .col1 {
      width: 100%;
    }
    .col2 {
      width: 100%;
      border-radius: 6px;
      .img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
`

const ButtonSection = styled.div`
  display: flex;
  grid-template-columns: auto auto;
  margin: 20px 0;
  @media (max-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`

const RoundButton = styled.button`
  cursor: pointer;
  margin: 0 20px 0 0;
  outline: none;
  border: none;
  width: 190px;
  padding: 10px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: bold;
  color: ${props => props.theme.accent};
  background: ${props => props.theme.reviewCardBg};
  transition: scale 1s cubic-bezier(0.2, 0.8, 0.2, 1);
  :hover {
    transform: scale(0.98);
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

const ContactCell = styled.div`
  padding: 50px 0 100px 0;
`

const Marquee = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-bottom: ${props => props.theme.line};
  padding: 30px 0;
  h3 {
    font-weight: 800;
    letter-spacing: -0.8px;
    text-align: left;
  }

  @media (max-width: 600px) {
    padding: 20px 0 10px;
  }
`

const Styles = styled.div`
  h5 {
    font-weight: normal;
    font-size: 22px;
    text-align: left;
    margin: 0;
  }
  h6 {
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
  }
  p {
    font-size: 18px;
    opacity: 0.5;
    text-align: left;
    margin: 0;
  }
`
